import {ExtendedNavigationView} from "./extendedNavigationView";
import {RuntimeInfo} from "../resources/classes/RuntimeInfo";
import {ConfigService} from "../resources/services/ConfigService";

export class wardExtended extends ExtendedNavigationView {
    //http://localhost:8080/#/wardExtended/60ccef5f-8cda-44cc-9e6e-51762da794ce/Givenname/FamilyName/UserId
    //http://localhost:8080?stay=1/#/wardExtended/2-A6/Givenname/FamilyName/UserId
    wardNameOrId: string;
    ward: any;
    logDiv: HTMLDivElement;

    async activate(params) {
        await super.activate(params);
        this.wardNameOrId = params["nameOrId"];
    }

    log(line: string) {
        if (!this.logDiv) return;
        let newChild = document.createElement("div");
        newChild.innerHTML = line;
        this.logDiv.appendChild(newChild);
    }

    async attached() {
        await super.attached();

        this.taskQueue.queueTask(async () => {
            if (this.wardNameOrId && this.userService.isLoggedIn) {
                if (ConfigService.Debug) this.log("[WardExtended] - Loading Locations");
                await this.locationService.fetch();

                this.log("Searching Locations for " + this.wardNameOrId);

                this.ward = this.locationService.wards.find(o =>
                    o.id.toUpperCase() === this.wardNameOrId.toUpperCase()
                    || (o.identifier && o.identifier.filter(f => f.value && f.value.toUpperCase() === this.wardNameOrId).length > 0)
                );

                if (!this.ward) {
                    this.ward = this.locationService.wards.find(o => o.name && o.name.toUpperCase() === this.wardNameOrId.toUpperCase());
                }

                this.log(this.wardNameOrId + (this.ward ? " found" : " not found"));

                if (!this.ward) {
                    let message = this.i18n.tr("ward_no_location_found").replace("%ID%", this.wardNameOrId);
                    this.dialogMessages.prompt(message, this.i18n.tr("information"), true);
                } else {
                    RuntimeInfo.CurrentWardId = this.ward.id;
                    if (window.location.href.indexOf("stay=1") === -1) {
                        this.router.navigateToRoute("ward", {id: this.ward.id});
                    } else {
                        this.log("Staying because of param stay=1");
                        this.log(`<a href="#/ward/${this.ward.id}">Open resulting Page</a>`);
                    }
                }
            }
        });
    }
}
