import {Router} from "aurelia-router";
import {autoinject} from "aurelia-framework";
import {FhirService} from "../resources/services/FhirService";
import {fhirEnums} from "../resources/classes/fhir-enums";
import {RuntimeInfo} from "../resources/classes/RuntimeInfo";
import {EncounterService} from "../resources/services/EncounterService";
import {ConfigService} from "../resources/services/ConfigService";

@autoinject
export class visitView {
    fhirService: FhirService;
    router: Router;

    constructor(router: Router, public encounterService: EncounterService) {
        this.router = router;
        this.fhirService = new FhirService();
    }

    _errorString: string = "";
    get errorString() {
        return this._errorString;
    }

    set errorString(value) {
        if (!value) {
            this._errorString = "";
            return;
        }

        if (value.indexOf('"') === 0) {
            value = value.substr(1);
        }

        if (value.indexOf('"') === value.length - 1) {
            value = value.substr(0, value.length - 1);
        }

        this._errorString += value + "<br />";
    }

    ready: boolean = false;
    _encounterId: string = undefined;
    get encounterId() {
        return this._encounterId;
    }

    set encounterId(value) {
        this._encounterId = value;

        if (value) {
            this.processEncounterquery();
        }
    }

    get baseUrl(): string {
        return FhirService.Endpoint;
    }

    debug(vars, obj?) {
        if (ConfigService.Debug) {
            console.debug(vars, obj);
            this.errorString = vars;
        }
    }

    getEncounter(id: string) {
        return new Promise<any>((resolve, reject) => {
            try {
                let sIdentifierUrl = "Encounter?identifier=" + id;
                if (ConfigService.Debug)
                    this.debug("Searching for Encounter by identifier " + sIdentifierUrl);
                else
                    this.errorString = "Patient wird aufgerufen ...";

                this.fhirService.fetch(sIdentifierUrl)
                    .then((result: any[]) => {
                        if (result.length > 0) {
                            this.debug("Encounter found by identifier " + this.encounterId);
                            resolve(result[0]);
                            return result[0];
                        }

                        sIdentifierUrl = `${fhirEnums.ResourceType.encounter}?_id=${id}`;
                        this.debug("<span style='color:orange'>Encounter by identifier not found, trying _id " + sIdentifierUrl + "</span>");
                        this.fhirService.get(sIdentifierUrl)
                            .then((result2: any) => {
                                if (typeof result2 !== "undefined" && result2.entry && result2.entry.length > 0) {
                                    this.debug("Encounter found by _id " + this.encounterId);
                                    resolve(result2);
                                    return result2;
                                }

                                let msg = ("<span style='color:red'>Encounter not found neither with _id not with identifier = " + id + "</span>");
                                // this.debug(msg);
                                // this.errorString = JSON.stringify(msg);
                                reject(msg);
                                return;
                            })
                            .catch(error => {
                                if (error && error.statusCode) {
                                    if (error.statusCode == 404) {
                                        this.errorString = "Encounter does not exist";
                                        reject("Encounter does not exist");
                                        return undefined;
                                    }
                                } else {
                                    console.warn(error);
                                    this.errorString = JSON.stringify(error);
                                    reject(error);
                                    return;
                                }
                            })
                    })
                    .catch(error => {
                        console.warn(error);
                        this.errorString = JSON.stringify(error);
                        reject(error);
                        return;
                    })
            } catch (e) {
                this.errorString = e.message || JSON.stringify(e);
                reject(e);
            }
        });
    }

    goToPatient(encounter) {
        try {
            if (encounter.subject && encounter.subject.reference) {
                // let newUrl = "#/Encounter/" + ref;
                let newUrl = "#/Encounter/" + encounter.id;
                this.debug("<span style='color:#005f25'>Patient-Ref and Encounter found, navigate to: " + newUrl + "</span>");
                if (window.location.href.indexOf("stay=1") === -1) {
                    // window.location.hash = newUrl;
                    this.router.navigateToRoute("encounter", {id: encounter.id});
                    /* App.Router.navigate(newUrl)
                        .then(() => {
                            App.hideRightLoading();
                        })
                        .catch(error => {
                            this.errorString = JSON.stringify(error);
                            console.warn(error);
                        }); */
                } else {
                    this.errorString = "staying on page because of url param 'stay'";
                    this.errorString = `<a onclick="window.location.hash='${newUrl}';">Go to resulting page</a>`;
                    RuntimeInfo.IsLoading = false;
                }
            } else {
                if (!encounter.subject) {
                    this.errorString = "Encounter has no subject.";
                } else {
                    if (!encounter.subject.reference) {
                        this.errorString = "Encounter.Subject has no reference";
                    }
                }

                RuntimeInfo.IsLoading = false;
            }
        } catch (e) {
            this.errorString = e.message || JSON.stringify(e);
            RuntimeInfo.IsLoading = false;
        }
    }

    async processEncounterquery() {
        if (!this.ready) return;
        this._errorString = '';
        try {
            await this.encounterService.pump(this.encounterId);
            RuntimeInfo.IsLoading = true;
            // this.errorString = "view attached, calling getEncounter()";
            this.getEncounter(this.encounterId)
                .then(encounter => {
                    this.debug("Encounter found ", encounter);
                    this.debug("calling goToPatient");

                    if (typeof encounter !== "undefined")
                        // HomeList.gotoEncounter(encounter.id);
                        this.goToPatient(encounter);
                    return;
                })
                .catch(error => {
                    this.errorString = JSON.stringify(error);
                    console.warn(error);
                    RuntimeInfo.IsLoading = false;
                    return;
                });
        } catch (e) {
            this.errorString = "in processEncounterQuery: " + (e.message || JSON.stringify(e));
            RuntimeInfo.IsLoading = false;
            return;
        }
    }

    attached() {
        this.ready = true;
        this.processEncounterquery();
    }

    activate(params) {
        try {
            this.encounterId = params.id;
        } catch (e) {
            this.errorString = "in activate: " + (e.message || JSON.stringify(e));
        }
    }
}
